import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'backstage',
    loadChildren: () => import('./backstage/backstage.routes'),
  },

  {
    path: 'm',
    title: '參與者用',
    loadChildren: () => import('./mobile/mobile.routes'),
  },

  {
    path: 'dev',
    title: '開發用',
    loadChildren: () => import('./dev/dev.routes'),
  },

  {
    path: 'playground',
    loadComponent: () => import('./playground/playground.component'),
  },

  {
    path: 'invite',
    redirectTo: 'backstage/login/invite',
  },

  {
    path: '**',
    redirectTo: 'backstage',
  },
];
